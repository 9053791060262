import React from 'react';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Grid from '@nubank/nuds-web/components/Grid/Grid';
import Image from '@nubank/nuds-web/components/Image/Image';
import Link from '@nubank/nuds-web/components/Link/Link';

import generateDPRsForCMSImage from '@nubank/www-latam-commons/utils/cms/images/generateDPRsForCMSImage';
import LazyBgBox from 'components/LazyBgBox/LazyBgBox';

const imageSrcSet = generateDPRsForCMSImage([{
  imageMobile: { url: 'https://nubank.com.br/images-cms/1713551014-6-dobra-cartao-tablet-1.jpg' },
  imageTablet: { url: 'https://nubank.com.br/images-cms/1713551014-6-dobra-cartao-tablet-1.jpg' },
  imageDesktop: { url: 'https://nubank.com.br/images-cms/1713551014-6-dobra-cartao-tablet-1.jpg' },
}]);

function SectionWantedVariant() {
  return (
    <LazyBgBox
      tag="section"
      display="flex"
      backgroundColor="white.dark"
      paddingVertical={{ xs: '12x', md: '20x', lg: '32x' }}
      minHeight={{ xs: '125vw', md: '100vw', lg: 'calc(100vh - 3.5rem)' }}
    >
      <Grid
        paddingLeft={{ xs: '8x', md: '16x' }}
        paddingRight={{ xs: '8x', md: '16x' }}
        rowGap="12x"
        alignItems={{ lg: 'center' }}
      >
        <Grid.Child
          gridColumn={{ xs: '1 / span 4', md: '2 / span 4', lg: '2 / span 5' }}
          gridRow={{ xs: '2', lg: '1' }}
        >
          <Image
            loading="lazy"
            srcSet={imageSrcSet}
            alt="Foto Mão segurando smartphones"
            Title="Smatphones"
          />
        </Grid.Child>
        <Grid.Child
          gridColumn={{ xs: '1 / span 4', md: '1 / span 6', lg: '8 / span 4' }}
          rowGap="0px"
        >
          <Typography
            variant="heading2"
            color="black"
            marginBottom="4x"
          >
            Você no controle da sua vida financeira.
          </Typography>
          <Link
            color="primary"
            href="https://blog.nubank.com.br/app-nubank-de-cara-nova-o-que-mudou/?utm_source=linkedin&utm_medium=social&utm_campaign=awareness&utm_content=s&lp=https://blog.nubank.com.br/app-nubank-de-cara-nova-o-que-mudou/"
            target="_blank"
            variant="action"
          >
            Conheça o novo app
          </Link>
        </Grid.Child>
      </Grid>
    </LazyBgBox>
  );
}

export default React.memo(SectionWantedVariant);
